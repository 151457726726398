.App {
  background-color: rgb(7, 32, 53);
}

.App-header {
  background-color: rgb(7, 32, 53);
  min-height: 10vh;
  height: 10vh;
  
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-body {
  background-color: rgb(31, 12, 56);
  background-image: linear-gradient(rgb(7, 32, 53), rgb(31, 12, 56));
  min-height: 90vh;
  height: 90vh;

  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.App-column {
  float: left;
  width: 50%;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

h1.App-logo-text {
  font-family: 'Poiret One', cursive;
  font-size: calc(16px + 2vmin);
  margin-left: 3vh;
  margin-top: 1vh;
}


.App-grid-container {
  height: min(80vh,40vw);
  width: min(80vh,40vw);
  background-color: #0000002d;
  margin: min(4vh,5vw);
}

.App-svg {
  height: min(80vh,40vw);
  width: min(80vh,40vw);
  outline: none;
}

p.WalletAddress {
  font-family: sans-serif;
  font-size: calc(8px + 1vmin);
  margin-left: 3vh;
  margin-top: 1vh;
  align-self: center;
}

div.game-button {
  flex: 33%;
  padding: 1 px;
}

div.flex-container {
  display: flex;
}













